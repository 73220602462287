const AdditionalLocationInfo = {
    bindings: {
        locationInfo: '='
    },
    template: require('./additionalLocationInformation.html'),
    controller: function () {
        var ctrl = this
        ctrl.locationInfo = []
        ctrl.showingAdditionalLocation = false;
        ctrl.additionalInfoTypes = [
            {
                name: 'Department',
                id: 'department',
                index: 0,
                charLimit: 70,
                regex: /.*/,
            },
            {
                name: 'Sub department',
                id: 'subDepartment',
                index: 1,
                charLimit: 70,
                regex: /.*/,
            },
            {
                name: 'Post box',
                id: 'postBox',
                index: 2,
                charLimit: 16,
                regex: /.*/,
            },
            {
                name: 'Floor',
                id: 'floor',
                index: 3,
                charLimit: 70,
                regex: /.*/,
            },
            {
                name: 'Town location name',
                id: 'townLocationName',
                index: 4,
                charLimit: 35,
                regex: /.*/,
            },
            {
                name: 'District name',
                id: 'districtName',
                index: 5,
                charLimit: 35,
                regex: /.*/,
            }
        ]
        ctrl.availableLocationTypes = ctrl.additionalInfoTypes.slice();

        function allLocationTypesAreSelected() {
            console.log('location types are selected', ctrl.availableLocationTypes.length === 0)
            return ctrl.availableLocationTypes.length <= 0;
        }

        function shouldShowDropdownList() {
            console.log('location types are selected', ctrl.availableLocationTypes.length !== 0)
            return ctrl.availableLocationTypes.length > 0;
        }

        function isAddLocationFieldDisabled() {
            return (ctrl.showingAdditionalLocation && ctrl.locationInfo.some(info => !info.value.length || info.type.id === 'blank')) || ctrl.allLocationTypesAreSelected();
        }

        function removeAdditionalLocation(index) {
            if (ctrl.locationInfo.length <= 1) {
                ctrl.showingAdditionalLocation = false;
            }
            const item = ctrl.locationInfo.splice(index, 1); 
            ctrl.availableLocationTypes[item.index] = item;
            ctrl.updateAvailableAdditionalLocationTypes();
        }

        function addAdditionalLocation() {
            if (ctrl.locationInfo.length >= 6) return;
            if (!ctrl.showingAdditionalLocation) {
                ctrl.showingAdditionalLocation = true;
            }
            ctrl.locationInfo.push({value: '', type: { name: 'Select type', id: 'blank', charLimit: 70, regex: /.*/ }})
        }

        function setAdditionalInfoType(index, type) {
            ctrl.locationInfo[index].type = type;
            updateAvailableAdditionalLocationTypes();
        }

        function updateAvailableAdditionalLocationTypes() {
            ctrl.availableLocationTypes = ctrl.additionalInfoTypes.slice().filter(infoType => {
                const result = !ctrl.locationInfo.some(info => info.type.id === infoType.id);
                return result;
            });
        }

        ctrl.addAdditionalLocation = addAdditionalLocation;
        ctrl.setAdditionalInfoType = setAdditionalInfoType;
        ctrl.updateAvailableAdditionalLocationTypes = updateAvailableAdditionalLocationTypes
        ctrl.removeAdditionalLocation = removeAdditionalLocation;
        ctrl.isAddLocationFieldDisabled = isAddLocationFieldDisabled;
        ctrl.allLocationTypesAreSelected = allLocationTypesAreSelected;
        ctrl.shouldShowDropdownList = shouldShowDropdownList;
   }
}

export default AdditionalLocationInfo;