/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

interface AgileticsWebEscrowSsoResponse {
    content: string;
    errorMessages: string[];
}

export default class AgileticsWebEscrowSsoRequest {
    static async getAgileticsWebEscrowSsoUrl() {
        return (await TmHttpClient.getInstance()).request<any>(`Authentication/Agiletics`, {
            method: 'POST',
        });
    }

    static async login(url: string, body: any) {
        console.log({ url });
        const params = new URLSearchParams({
            SAMLResponse: body.samlResponse,
            RelayState: body.relayState,
        });
        return (
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params,
            })
        ).text();
    }
}
